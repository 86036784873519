@import "../base/color";
@import "../base/typography";

$theme-colors: (
  "primary": $primary,
  "default": $light-gray,
  "danger": $danger,
  "warning": $warning,
  "success": $primary,
  "info": $info,
);

:root {
  --tab-active: #eaf3ff;
  --table-striped-active: #eaf3ff;
}

.main-container {
  height: 100%;
  display: flex;
  padding-top: 70px;

  .content {
    max-height: 100vh;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &-table {
      margin-top: 33px;
    }
  }
}

.loading {
  margin: 20px 0;

  span {
    margin-left: 1rem;
    font-weight: 500;
    font-size: 1.25rem;
  }
}

.pill {
  padding: 2.5px 5px;
  border-radius: 16px;
  font-size: 0.75rem;
  color: $fontPrimary;

  &.warning {
    background-color: $warning;
  }

  &.success {
    background-color: $primary;
  }
}

@import "~bootstrap/scss/bootstrap";
@import "react-toastify/dist/ReactToastify.css";
