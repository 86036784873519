$primary: #215732;
$primaryLight: #daf9e4;
$default: #212529;
$fontPrimary: #000;
$fontSecondary: #303030;
$fontTertiary: #525252;
$bgHover: #e7e7e732;
$bgHover2: #e7e7e748;
$bgHoverSecondary: #3f3f3f;
$bgHoverPrimary: #1b3624;
$white: #fff;
$input: #fff;

$off-white: #f4f4f4;
$gray: #f8fafc;
$light-gray: #9e9b9b;
$green: #29a745;
$danger: #dc3545;
$warning: #ffc107;
$info: #62b1f6;

$checkBox: #446c3c;
$checkBoxCheked: #285627;
$checkBoxDisabled: #6d8b6b;

$boxShadow: #acc2d9;

$skeletonPrimary: #f9f9f9;
$skeletonSecondary: #f4f4f4;
