@import "../../../../../../styles/base/color";

.navbar-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-bars {
  margin: 1rem;
  font-size: 1rem;
  background: none;
}

.notification {
  &-menu {
    margin: 1rem;
    font-size: 1.5rem;
  }

  &-icon {
    position: relative;
  }

  &-number {
    position: absolute;
    top: 10px;
    right: 7px;
    font-weight: 700;
    padding: 0 6px;
    border-radius: 50%;
    background: $danger;
    color: $white;
    font-size: 0.675rem;
  }
}

.nav-menu {
  background: $primary;
  height: 80px;
  width: 100%;
  max-width: 275px;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: -100%;
  z-index: 999;
  transition: 850ms;
  overflow-y: auto;

  &-notifications {
    background: $off-white;
    width: 350px;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 999;
    transition: 850ms;
    padding: 10px;
    overflow-y: auto;

    &.active {
      right: 0;
      transition: 350ms;
    }
  }

  &-items {
    width: 100%;
    padding: 0;

    & > li {
      padding: 10px 10px 10px 10px;
      background-color: $bgHover2;
      border-bottom: 1px solid $bgHover;
    }
  }

  &.active {
    left: 0;
    transition: 350ms;
  }

  .nav-text {
    text-decoration: none;
    color: $white;
    font-size: 0.875rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &.active,
    &:hover {
      li {
        background-color: $bgHover;
      }
    }

    li {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      list-style: none;

      &:hover .aviso {
        color: #215732;
      }
    }
  }
}

.navbar-toggle {
  background: transparent;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#user-menu {
  li,
  a {
    color: $green !important;
  }
}
