@import "../base/color";

.table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  margin-bottom: 0px;

  tr {
    &.disabled-row {
      background-color: #c4c4c4 !important;

      .disabled-item {
        pointer-events: none !important;
      }
    }

    &.recomendado-row {
      background-color: #62b1f66e !important;
    }

    &.not-viewed {
      background-color: #f0f8ff !important;
    }

    &.highlighted {
      background-color: #fff3b2 !important;
    }

    &.pieza-row {
      border-bottom: 0.25px solid $fontTertiary;
    }

    &:first-child {
      td:first-child {
        border-top-left-radius: 10px;
      }
      td:last-child {
        border-top-right-radius: 10px;
      }
    }

    &:nth-child(2n):not(.pieza-row) {
      td:first-child {
        border-bottom-left-radius: 15px;
      }
      td:last-child {
        border-bottom-right-radius: 15px;
      }
    }

    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      td:first-child {
        border-bottom-left-radius: 15px;
      }
      td:last-child {
        border-bottom-right-radius: 15px;
      }
    }
  }

  th,
  td {
    font-size: 0.75rem;
    border: none !important;
  }

  th {
    padding: 0.5rem 10px;
    font-weight: 400;
    text-transform: uppercase;
  }

  td {
    padding: 15px 10px;
    font-weight: 400;

    &:last-child .action {
      cursor: pointer;
    }

    .action {
      font-size: 1rem;

      &:not(&:last-child) {
        margin-right: 5px;
      }
    }
  }

  tbody {
    border: none !important;

    tr.border-light:not(:last-child) {
      border-bottom: 0.25px solid $fontTertiary !important;
    }
    tr.border-light:last-child {
      border-bottom: none !important;
    }

    .no-border {
      border-bottom: none !important;
    }

    tr {
      border-radius: 10px;
      border-bottom: 12px solid $white;

      td {
        vertical-align: middle;
        background-color: #f5f5f7 !important;
      }

      td .actions {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        justify-content: center;
        row-gap: 5px;
      }
    }

    thead {
      tr {
        border-bottom: 1px solid $white;
      }
    }
  }

  &-responsive {
    width: 100%;
  }

  .table-tag {
    background-color: $white;
    padding: 6px 12px;
    color: $primary;
    border-radius: 10px;
  }
}

.total-table {
  width: 100%;
  border: none;
  border-collapse: collapse;
  margin-bottom: 0px;

  tbody {
    tr {
      td {
        padding: 6px 12px;

        small {
          font-size: 0.875rem !important;
          font-weight: 400 !important;
          color: $fontTertiary !important;
        }

        p {
          font-size: 1.15rem !important;
          font-weight: 400 !important;
          color: $fontPrimary !important;
        }
      }

      &:nth-last-child(2) {
        border-bottom: 10px solid $white;
      }

      &.total {
        margin-top: 10px;
        background-color: $default !important;
        border-radius: 10px !important;

        td {
          padding: 12px 12px;

          &:first-child {
            border-top-left-radius: 17.5px;
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-top-right-radius: 17.5px;
            border-bottom-right-radius: 10px;
          }

          small,
          p {
            color: $white !important;
            font-weight: 700 !important;
          }
        }
      }
    }
  }
}

.MuiTable-root {
  thead {
    tr th {
      border-bottom: 1px solid $primary !important;
    }
  }

  th,
  td {
    font-size: 0.75rem;
    border: none !important;
  }

  th {
    padding: 0.5rem 10px;
    font-weight: 400;
    text-transform: uppercase;
  }

  td {
    padding: 15px 10px;
    font-weight: 400;
  }
}

/** MUI TABLE **/
.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin: 0;
}
