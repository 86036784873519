@import "../../../../../../styles/base/color";

.notificacion {
  position: relative;
  background-color: $white;
  box-shadow: $boxShadow;
  padding: 10px 20px;
  border-left: 4px solid $info;
  display: flex;
  align-items: center;

  p {
    font-size: 0.875rem;
    margin: 0;
  }

  .fecha {
    font-size: 0.675rem;
    font-weight: 700;
    color: $green;
  }

  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
  }

  margin-bottom: 15px;
}
