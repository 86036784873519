@import "../../../../../../styles/base/color";

.dashboard {
  position: relative;
  .summary {
    h1 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  .show-ecommerce-btn {
    position: absolute;
    top: 0;
    right: 0px;

    .shitchBtn {
      position: relative;
      display: inline-block;
      width: 122px;
      height: 34px;
    }
    input {
      display: none;
    }
    .slide {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      padding: 8px;
      color: #fff;
    }
    .slide:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 90px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
    input:checked + .slide {
      background-color: $primary;
      padding-left: 40px;
    }
    input:focus + .slide {
      box-shadow: 0 0 1px #01aeed;
    }
    input:checked + .slide:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
      left: -20px;
    }
    .slide.round {
      border-radius: 24px;
    }
    .slide.round:before {
      border-radius: 50%;
    }
  }

  .incidencias-metrics-loading {
    .content {
      overflow-y: auto !important;
    }
  }
}
