@import "typography";
@import "color";

%height-max {
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
}

html {
  @extend %height-max;
}

body {
  @extend %height-max;

  overflow-y: hidden !important;
  font-family: $rubik;
}

#root {
  width: 100%;
  @extend %height-max;
  overflow: hidden;
}

a,
a:hover {
  text-decoration: unset;
  color: $green !important;
}

ul,
li {
  list-style-type: none;
}

.action {
  cursor: pointer !important;
}

h1 {
  font-weight: 700 !important;
  font-size: 1.25rem !important;
  color: $primary !important;
  margin: 0;
}

h3 {
  font-weight: 700 !important;
  font-size: 1rem !important;
  margin: 0;
}

p {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  color: $fontPrimary !important;
  margin: 0;
}

label {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  color: $fontTertiary !important;
}

.text-green {
  color: $green !important;
}

.text-red {
  color: $red !important;
}

/*.MuiAutocomplete-inputRoot, 
.MuiAutocomplete-inputRoot:focus,
.MuiOutlinedInput-root,
.MuiAutocomplete-input
{
  border: none !important;
}*/

.css-nnbavb {
  display: none;
}

.MuiOutlinedInput-root {
  padding: 65px !important;
  padding: 0 !important;
}

.sheet .MuiTabPanel-root {
  padding: 24px 12px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $green;
}

.MuiTabPanel-root {
  padding: 24px 0 !important;
}

.css-187ruhz-MuiInputBase-root-MuiFilledInput-root {
  background: transparent !important;
}
.css-1l6di18 .MuiFilledInput-root {
  background: transparent !important;
  padding-right: 8px !important;
}

.MuiAutocomplete-tag {
  background-color: $gray !important;
  border-radius: 16px !important;
}

.MuiPaginationItem-root {
  background-color: $white !important;
  color: $primary !important;

  &.Mui-selected {
    background-color: $primaryLight !important;
  }
  &.MuiPaginationItem-previousNext {
    border: 1px solid $primary !important;
  }
}

.MuiPopper-root { z-index: 999; }