@import "./color";
@import "./typography";

body {
  overflow: hidden;
  background-color: #fff;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $rubik !important;
  line-height: 19px;
  padding: 0 !important;
}

footer {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: $light-gray;
}

.custom-card {
  background: $white;
  padding: 24px;
  box-shadow: 0px 0px 20px rgba(171, 171, 171, 0.25);
  border-radius: 10px;

  & > div > div {
    column-gap: 10px;
  }

  .icon {
    background-color: $primaryLight;
    padding: 15px 20px;
    border-radius: 12px;
    font-size: 1.25rem;
  }
}

.position-relative {
  .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.card-item {
  padding: 10px 12px;
  border-radius: 16px;
  background-color: $gray;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.legend {
  p {
    margin: 0 !important;
    font-size: 0.785rem;
  }
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.highlight {
  background-color: yellow;
}

.date-selector {
  p {
    font-size: 0.875rem;
    text-transform: capitalize;
  }
}

.default {
  font-size: 0.675rem;
}

.comentario {
  padding: 15px;
  border-radius: 16px;
  background-color: #f5f5f5;
  width: auto;

  span {
    font-size: 0.688rem;
    font-weight: 700;
  }

  p {
    font-size: 0.875rem;
    margin: 0;
  }
}

.chat-comment {
  text-align: right;
}

.chat-info {
  position: absolute;
  right: 5px !important;
  left: revert;
}

span.link {
  color: $primary;
  cursor: pointer;
}

.content-info {
  padding: 20px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    text-transform: uppercase;

    &:not(h1:first-child) {
      margin-top: 20px;
    }
  }

  h1 {
    font-size: 1.563rem;
  }

  h2 {
    font-size: 1.375rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.125rem;
  }

  h5 {
    font-size: 1rem;
  }

  p {
    font-size: 0.875rem;
    margin: 0;
    text-align: justify;
  }

  ul {
    list-style: disc;
    margin-top: 10px;

    li {
      list-style: disc;
      font-size: 0.875rem;
    }
  }
}

.link {
  background-color: transparent;
  border: none;
  padding: 0;
}

.card-grid {
  column-gap: 2rem;

  .card-item {
    flex-grow: 1;
    column-gap: 1rem;
    box-shadow: 0 5px 20px -10px $boxShadow;
    padding: 15px;

    h2 {
      font-size: 1rem;
      font-weight: 500;
    }

    p {
      margin-top: 0.5rem;
      font-size: 1.75rem;
      margin-bottom: 0.5rem;
    }

    &.mensajes {
      p {
        font-size: 0.875rem;
        text-align: justify;
      }
    }

    &-talleres {
      padding: 10px 20px;

      h5 {
        font-size: 0.875rem;
        text-transform: uppercase;
      }

      p {
        font-size: 0.875rem;
        margin-bottom: 0;
        font-weight: 700;
      }
    }
  }
}

.disabled {
  background-color: #dcdcdc;
  pointer-events: none !important;
}

.disabled-option {
  pointer-events: none !important;
  color: grey !important;
}

.recomendado {
  background-color: $info;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  line-height: 9px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
